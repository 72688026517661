var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLREV", topClass: "topcolor-orange" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: _vm.editable && _vm.isRevision,
                                  editable: _vm.editable && _vm.isRevision,
                                  label: "LBLREVNO",
                                  name: "revisionNum",
                                },
                                model: {
                                  value: _vm.usePlan.revisionNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.usePlan, "revisionNum", $$v)
                                  },
                                  expression: "usePlan.revisionNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "LBLREVPERIOD",
                                  name: "regDtStr",
                                },
                                model: {
                                  value: _vm.usePlan.regDtStr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.usePlan, "regDtStr", $$v)
                                  },
                                  expression: "usePlan.regDtStr",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "LBLREVUSER",
                                  name: "regUserName",
                                },
                                model: {
                                  value: _vm.usePlan.regUserName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.usePlan, "regUserName", $$v)
                                  },
                                  expression: "usePlan.regUserName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: _vm.editable && _vm.isRevision,
                                  editable: _vm.editable && _vm.isRevision,
                                  label: "LBLREVREASON",
                                  name: "revisionContent",
                                },
                                model: {
                                  value: _vm.usePlan.revisionContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.usePlan,
                                      "revisionContent",
                                      $$v
                                    )
                                  },
                                  expression: "usePlan.revisionContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "공사현장 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-select" },
                    [
                      _vm.popupParam.smfUsePlanId
                        ? _c("c-select", {
                            attrs: {
                              editable: !_vm.isRevision,
                              comboItems: _vm.gridrev.data,
                              type: "custom",
                              typetxt: "개정이력",
                              itemText: "revisionNum",
                              itemValue: "smfUsePlanId",
                              name: "selectedSmfUsePlanId",
                              label: "",
                            },
                            on: { input: _vm.rowClickRev },
                            model: {
                              value: _vm.selectedSmfUsePlanId,
                              callback: function ($$v) {
                                _vm.selectedSmfUsePlanId = $$v
                              },
                              expression: "selectedSmfUsePlanId",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.isRevision &&
                                  !_vm.isHistory &&
                                  _vm.isOld,
                                expression:
                                  "editable && !isRevision && !isHistory && isOld",
                              },
                            ],
                            attrs: { label: "LBLREV", icon: "restart_alt" },
                            on: { btnClicked: _vm.setRevision },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.isRevision &&
                                  !_vm.isHistory,
                                expression:
                                  "editable && isRevision && !isHistory",
                              },
                            ],
                            attrs: {
                              label: "LBLREVCANCEL",
                              icon: "restart_alt",
                            },
                            on: { btnClicked: _vm.cancelRevision },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.isRevision &&
                                  !_vm.disabled &&
                                  !_vm.isHistory &&
                                  _vm.isOld,
                                expression:
                                  "editable && !isRevision && !disabled && !isHistory && isOld",
                              },
                            ],
                            attrs: {
                              url: _vm.permitUrl,
                              isSubmit: _vm.isSubmit,
                              param: _vm.usePlan,
                              mappingType: "PUT",
                              label: "제출",
                              icon: "check",
                            },
                            on: {
                              beforeAction: _vm.permitData,
                              btnCallback: _vm.permitCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  !_vm.isHistory,
                                expression:
                                  "editable && !disabled && !isHistory",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.usePlan,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            disabled: true,
                            editable: _vm.editable,
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.usePlan.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.usePlan, "plantCd", $$v)
                            },
                            expression: "usePlan.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "공사명",
                            name: "projectName",
                          },
                          model: {
                            value: _vm.usePlan.projectName,
                            callback: function ($$v) {
                              _vm.$set(_vm.usePlan, "projectName", $$v)
                            },
                            expression: "usePlan.projectName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            type: "number",
                            label: "안전보건관리비 예산(원)",
                            name: "projectSafetyCost",
                          },
                          model: {
                            value: _vm.usePlan.projectSafetyCost,
                            callback: function ($$v) {
                              _vm.$set(_vm.usePlan, "projectSafetyCost", $$v)
                            },
                            expression: "usePlan.projectSafetyCost",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "공사기간",
                            name: "projectPeriod",
                          },
                          model: {
                            value: _vm.usePlan.projectPeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.usePlan, "projectPeriod", $$v)
                            },
                            expression: "usePlan.projectPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-vendor-multi", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "사용할 업체(복수)",
                            name: "smfVendorCds",
                          },
                          model: {
                            value: _vm.usePlan.smfVendorCds,
                            callback: function ($$v) {
                              _vm.$set(_vm.usePlan, "smfVendorCds", $$v)
                            },
                            expression: "usePlan.smfVendorCds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-table", {
                ref: "table",
                attrs: {
                  title: "항목별 사용계획",
                  tableId: "usePlanItem",
                  columns: _vm.grid.columns,
                  data: _vm.usePlan.itemResult,
                  gridHeight: "500px",
                  editable: _vm.editable && !_vm.disabled && !_vm.isHistory,
                  columnSetting: false,
                  expandAll: false,
                  isFullScreen: false,
                  usePaging: false,
                  hideBottom: true,
                  filtering: false,
                },
                on: { "table-data-change": _vm.tableDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "suffixTitle",
                    fn: function () {
                      return [
                        _vm.checkAmountTobeUsed
                          ? _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(
                                " ※ " + _vm._s(_vm.lackAmountTobeUsedText) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }